import { useState, useEffect } from "react";

const useMouseParallax = () => {
  const [transform, setTransform] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const isMobile = window.innerWidth < 768;

    if (isMobile) {
      return;
    }

    let lastAnimationRequestId;

    const handleMouseMove = (event) => {
      cancelAnimationFrame(lastAnimationRequestId);
      lastAnimationRequestId = requestAnimationFrame(() => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const moveX = (event.clientX - screenWidth / 2) * 0.05;
        const moveY = (event.clientY - screenHeight / 2) * 0.05;
        setTransform({ x: moveX, y: moveY });
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      cancelAnimationFrame(lastAnimationRequestId);
    };
  }, []);

  return transform;
};

export default useMouseParallax;
